.App {
  height: 100vh;
}

a[target='_blank'] {
  display: none !important;
}

.Navbar {
  position: fixed;
  z-index: 50;
  background: rgba(250, 250, 250, 0.8);
  border-bottom: 1px solid rgb(245 245 245);
  width: 100%;
  height: 96px;
}

.NavbarContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 96px;
  padding-left: 2rem;
  padding-top: 0.2rem;
  padding-right: 2rem;
}

.Content {
  padding-top: 96px;
  height: 100vh;
}

.Logo {
  height: 3.5rem;
  padding-bottom: 0.25rem;
}

.Menu {
  gap: 1.5rem;
  display: none;
}

.Menu a {
  color: rgb(38 38 38);
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s;
  font-family: 'Inter', sans-serif;
}

.Menu a:hover {
  color: rgb(14 165 233);
}

.Menu a span {
  color: rgb(14 165 233);
  border-bottom: 2px solid rgb(14 165 233);
  padding-bottom: 0.25rem;
}

@media (min-width: 520px) {
  .Menu {
    display: flex;
  }
}